import {
  VStack,
  HStack,
  IconButton,
  Box,
  Text,
  Spacer,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsFiles, BsFillDatabaseFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import {
  Navigate,
  Outlet,
  matchPath,
  redirect,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import {
  useUserWorkspaces,
  useWorkspaceDatasets,
  useWorkspacePromptGenerations,
  useWorkspacePrompts,
} from "../../service";
import { Dataset, Prompt, PromptGeneration } from "../../types";
import { FirebaseError } from "firebase/app";
import { useEditorState } from "./EditorState";
import { PROMPT_EDITOR_ROUTE, EVENT_PAGE_ROUTE, REGISTERED_ROUTES } from "../../routes";

const ICON_SIZE = "10";

const InnerEditorRoute = ({ workspaceId }: { workspaceId: string }) => {
  // Sort by alphabet, then created. Declare names as unique?
  const [workspacePrompts, workspacePromptsLoading, workspacePromptsError] =
    useWorkspacePrompts(workspaceId);
  const [workspaceDatasets, workspaceDatasetsLoading, workspaceDatasetsError] =
    useWorkspaceDatasets(workspaceId);

  const [
    workspacePromptGenerations,
    workspacePromptGenerationsLoading,
    workspacePromptGenerationsError,
  ] = useWorkspacePromptGenerations(workspaceId);
  
    // set workspaceId in EditorState
  const updateWorkspaceId = useEditorState((state) => state.updateActiveWorkspaceId);
  useEffect(() => {
    updateWorkspaceId(workspaceId);
  }, [workspaceId, updateWorkspaceId])

  const [activePromptId, setActivePromptId] = useState<string | undefined>(
    undefined
  );
  const [activeDatasetId, setActiveDatasetId] = useState<string | undefined>(
    undefined
  );


  return (
        <Outlet
          context={{
            workspacePrompts,
            workspacePromptsLoading,
            workspacePromptsError,
            workspaceDatasets,
            workspaceDatasetsLoading,
            workspaceDatasetsError,
            workspacePromptGenerations,
            workspacePromptGenerationsLoading,
            workspacePromptGenerationsError,
          }}
        />
  );
};

export const EditorRoute = () => {
  // Include the header + sidebar here

  const { workspaceId } = useParams();
  const [userWorkspaces, userWorkspaceLoading, userWorkspaceError] =
    useUserWorkspaces();

  const currentURL = useLocation();
  if (userWorkspaceLoading) {
    return <Text>Loading...</Text>;
  }
  if (userWorkspaceError) {
    return <Text>Error: {userWorkspaceError.message}</Text>;
  }
  if (!workspaceId) {
    // Redirect if registered!
    const routeName = currentURL.pathname.slice(1);
    if (REGISTERED_ROUTES.includes(routeName)) {
      return (
        <Navigate
          replace
          to={"/" + userWorkspaces?.[0]?.id + "/" + routeName}
        />
      );
    }
    return <Navigate replace to={"/" + userWorkspaces?.[0]?.id + "/" + PROMPT_EDITOR_ROUTE} />;
  }
  if (userWorkspaces?.find((x) => x.id === workspaceId) === undefined) {
    return <Text>Not found!</Text>;
  }
  return <InnerEditorRoute workspaceId={workspaceId} />;
};

type EditorContextType = {
  workspacePrompts: Prompt[];
  workspacePromptsLoading: boolean;
  workspacePromptsError: FirebaseError | undefined;
  workspaceDatasets: Dataset[];
  workspaceDatasetsLoading: boolean;
  workspaceDatasetsError: FirebaseError | undefined;
  workspacePromptGenerations: PromptGeneration[];
  workspacePromptGenerationsLoading: boolean;
  workspacePromptGenerationsError: FirebaseError | undefined;
};

export function useEditorContext() {
  return useOutletContext<EditorContextType>();
}
