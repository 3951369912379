import { Center, Spinner, Text, VStack } from "@chakra-ui/react";
import React from "react";

const LoadingPage = () => {
  return (
    <Center w="full" h="100vh">
      <VStack gap={3}>
        <Text as={"b"} fontSize="lg">
          Wale
        </Text>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="lg"
        />
      </VStack>
    </Center>
  );
};

export default LoadingPage;
