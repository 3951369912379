export const PROMPT_EDITOR_ROUTE = "prompt";
export const DATASET_EDITOR_ROUTE = "dataset";
export const SETTINGS_ROUTE = "settings";
export const HISTORY_PAGE_ROUTE = "history";
export const HISTORYCOMPARE_PAGE_ROUTE = "history-compare";
export const EVENT_PAGE_ROUTE = "event";
export const DASHBOARD_PAGE_ROUTE = "dashboard";
export const REGISTERED_ROUTES = [
  PROMPT_EDITOR_ROUTE,
  DATASET_EDITOR_ROUTE,
  SETTINGS_ROUTE,
  HISTORY_PAGE_ROUTE,
  HISTORYCOMPARE_PAGE_ROUTE,
  EVENT_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
];

const DEV_MODEL_ROUTE = "https://zeyuzhao--wale-ide-web-dev.modal.run";
const PROD_MODEL_ROUTE = "https://zeyuzhao--wale-ide-web.modal.run";

export const MODEL_ROUTE =
  process.env.NODE_ENV === "development" ? DEV_MODEL_ROUTE : PROD_MODEL_ROUTE;
