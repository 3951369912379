import React from 'react';

import { Flex, Center, Divider, Heading, Box, Text, Container, Progress, VStack, Link as ChakraLink } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";


import { OnboardState, useUserContext } from "./UserContext";
import { on } from "events";
import { ProviderRegistrationForm, ProviderRegistrationFormValues } from "./ProviderRegistrationForm";
import { updateDoc, doc, addDoc, setDoc, serverTimestamp, collection } from "firebase/firestore";
import { FormikHelpers } from "formik";
import firebase from "../firebase";

import {createDataset, workspaceConverter, createPrompt} from '../service';
import { defaultDatasets, defaultPrompts } from '../defaultData';



export const MultiOnboardPage = () => {
  const { user, firebaseUser, signOut, refreshUser } = useUserContext();

  async function onSubmitProviderRegistration({ 
    openAIApiKey,
    firstName,
    lastName,
    companyEmail,
    department,
   }: ProviderRegistrationFormValues,
    helpers: FormikHelpers<ProviderRegistrationFormValues>) {
    // Will write OR update user's openAiApiKey
    // console.log('creating');
    if (firebaseUser == null) {
      throw Error('Invalid authentication state: firebaseUser is undefined');
    }

    const userRef = doc(firebase.db, 'users', firebaseUser.uid)
    await setDoc(
      userRef,
      {
        // Name
        firstName,
        lastName,
        
        // For send_grid
        first_name: firstName,
        last_name: lastName,

        // Synced from firebase
        displayName: firebaseUser.displayName,
        email: firebaseUser.email,
        photoUrl: firebaseUser.photoURL,
        createdAt: serverTimestamp(),

        // Extras
        openAIApiKey,
        companyEmail,
        department,

        // Onboarding State
        onboardState: OnboardState.ProviderRegistration,
        complete: true,
      }
    )
    // Create workspace 
    const workspacesRef = collection(firebase.db, 'workspaces').withConverter(workspaceConverter);
    const workspaceRef = await (
       addDoc(workspacesRef, {
        name: 'Default Workspace',
        ownerUid: firebaseUser.uid,
        ownerName: firebaseUser.displayName ?? '',
        ownerEmail: firebaseUser.email ?? '',
        memberUids: [firebaseUser.uid],
        createdAt: serverTimestamp(),
      } as any)
    )
    const datasetRef = await createDataset({
      workspaceId: workspaceRef.id,
      ...defaultDatasets[0]
    });

    await createPrompt({
      workspaceId: workspaceRef.id,
      activeDatasetId: datasetRef.id,
      ...defaultPrompts[0],
    });
    await refreshUser();
  }

  function renderSwitch(onboardState: OnboardState | undefined) {
    switch (onboardState) {
      case undefined:
        return (<>
          <Box>
            <Heading as='h2' size='lg' mb={1} pt={2}>
              Onboarding
            </Heading>
          </Box>
          <ProviderRegistrationForm onSubmit={onSubmitProviderRegistration} />
        </>)
      case OnboardState.ProviderRegistration:
        return <Text>Unexpected</Text>;
      default:
        throw Error(`Unexpected onboard state: '${onboardState}'`);
    }
  }

  return (
    <Box>
      <Flex py={3} alignItems={'center'} borderBottomWidth={1}>
        <Box as='button' onClick={() => signOut()} px={5}>
          <CloseIcon w={3} h={3} />
        </Box>
        <Center height='30px' mr={5}>
          <Divider orientation='vertical' />
        </Center>
        <Text>Onboarding</Text>
      </Flex>
      <Flex width={'100%'} justifyContent='center' gap='2'>
        <Container maxW="container.md" px={10} pt={3}>
          {renderSwitch(user?.onboardState)}
        </Container>
      </Flex>
    </Box >
  )
}

