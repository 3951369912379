import {
  VStack,
  HStack,
  Button,
  Spacer,
  IconButton,
  Box,
  Text,
  Divider,
  Icon,
  Image,
  Center,
  Tooltip,
} from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { VscNewFile } from "react-icons/vsc";
import { OverflowWrapper } from "../../components/OverflowWrapper";
import {
  BsFiles,
  BsFillDatabaseFill,
  BsClockHistory,
  BsCollection,
  BsJournalCode,
  BsClipboardData,
  BsSpeedometer2,
} from "react-icons/bs";

import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { MdDragHandle } from "react-icons/md";
import {
  PanelGroup,
  Panel,
  PanelResizeHandle,
  ImperativePanelHandle,
} from "react-resizable-panels";
import { ImLab } from "react-icons/im";
import { IoMdSettings } from "react-icons/io";
import {
  PROMPT_EDITOR_ROUTE,
  DATASET_EDITOR_ROUTE,
  SETTINGS_ROUTE,
  HISTORY_PAGE_ROUTE,
  EVENT_PAGE_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  HISTORYCOMPARE_PAGE_ROUTE,
} from "../../routes";
import { useEditorState } from "./EditorState";
import HistorySidebarItem from "../../components/HistorySidebarItem";
import { usePostHog } from "posthog-js/react";

export function EditorMenuSidebar({ children }: { children: React.ReactNode }) {
  return (
    <VStack
      spacing={0}
      h="full"
      background="white"
      flex={0}
      borderLeftWidth={1}
      borderLeftColor="gray.100"
    >
      {children}
    </VStack>
  );
}
export function SidebarStructureMenu({
  items,
  menuHandler,
}: {
  menuHandler: () => void;
  items: {
    name: string;
    icon: React.ReactElement;
    onClick: () => void;
  }[];
}) {
  return (
    <VStack h="full" w="full" spacing={0}>
      <HStack h="40px" w="full">
        <Box pl={3} onClick={menuHandler}>
          <Text fontWeight={"bold"}>Variables</Text>
        </Box>
      </HStack>
      <Box flex="1" h="full" w="full">
        <OverflowWrapper>
          <VStack w="full" h="full" px={1} spacing={1}>
            {items.map((x, i) => (
              <Button
                leftIcon={x.icon}
                variant="ghost"
                size="xs"
                w="full"
                justifyContent="flex-start"
                _hover={{ bg: "blackAlpha.300" }}
                px={2}
                key={i}
                onClick={x.onClick}
              >
                <Text fontSize={"sm"}>{x.name}</Text>
              </Button>
            ))}
          </VStack>
        </OverflowWrapper>
      </Box>
    </VStack>
  );
}

export function SidebarFileNav({
  sidebarItems: workspacePrompts,
  activeId,
  type,
}: {
  workspaceId: string;
  activeId?: string;
  type?: string;
  sidebarItems: {
    id: string;
    url: string;
    name: string;
    icon?: React.ReactElement;
    sidebarComponent?: React.ReactNode;
  }[];
}) {
  return (
    <Box flex="1" h="full" w="full">
      <OverflowWrapper>
        <VStack
          w="full"
          h="full"
          px={1}
          spacing={type === "history" ? 1 : 0}
          py={1}
        >
          {workspacePrompts?.map((x) => (
            <Button
              leftIcon={x.icon}
              variant="ghost"
              size={type === "history" ? "" : "xs"}
              w="full"
              justifyContent="flex-start"
              _hover={{ bg: "blackAlpha.300" }}
              bg={activeId === x.id ? "blackAlpha.300" : "transparent"}
              px={2}
              key={x.id}
              as={RouterLink}
              to={x.url}
            >
              {type === "history" ? (
                x.sidebarComponent
              ) : (
                <Text fontSize={"sm"}>{x.name}</Text>
              )}
            </Button>
          ))}
        </VStack>
      </OverflowWrapper>
    </Box>
  );
}

export function SidebarHeader({
  headerName,
  type,
  handleCreatePrompt,
}: {
  headerName: string;
  type?: string;
  handleCreatePrompt?: () => void;
}) {
  const navigate = useNavigate();
  const activeWorkspaceId = useEditorState((s) => s.activeWorkspaceId);
  const historyComparePath =
    "/" + activeWorkspaceId + "/" + HISTORYCOMPARE_PAGE_ROUTE;
  const posthog = usePostHog();
  return (
    <HStack h={10} w="full" borderBottomWidth={1} borderBottomColor="gray.200">
      <Box pl={3} mt="0.5">
        <Text fontWeight={"bold"}>{headerName}</Text>
      </Box>
      <Spacer />
      <HStack px={2}>
        {type == "history" ? (
          <Button
            bg="blue.600"
            textColor="white"
            aria-label="New file"
            size="xs"
            _hover={{ bg: "blue.700" }}
            onClick={() => {
              posthog?.capture("activate_history_compare");
              navigate(historyComparePath)
            }}
          >
            Compare Mode
          </Button>
        ) : (
          <Button
            bg="blue.600"
            textColor="white"
            aria-label="New file"
            size="xs"
            _hover={{ bg: "blue.700" }}
            onClick={handleCreatePrompt}
          >
            New +
          </Button>
        )}
      </HStack>
    </HStack>
  );
}

export function ResizeHandleVertical() {
  const [resizeHandleHighlight, setResizeHandleHighlight] =
    useState<boolean>(false);

  return (
    <PanelResizeHandle
      onDragging={() => {
        setResizeHandleHighlight(!resizeHandleHighlight);
      }}
    >
      <VStack
        h="full"
        background={resizeHandleHighlight ? "gray.200" : "transparent"}
        w={resizeHandleHighlight ? "3" : ""}
        ml={resizeHandleHighlight ? "-3" : ""}
        justify="stretch"
      >
        <Divider orientation="vertical" h="full" borderColor="blackAlpha.300" />
      </VStack>
    </PanelResizeHandle>
  );
}

export function ResizeHandleHorizontal() {
  const [resizeHandleHighlight, setResizeHandleHighlight] =
    useState<boolean>(false);
  return (
    <PanelResizeHandle
      onDragging={() => {
        setResizeHandleHighlight(!resizeHandleHighlight);
      }}
    >
      <HStack
        w="full"
        background={resizeHandleHighlight ? "gray.200" : "transparent"}
        h="3"
        justify="stretch"
      >
        <Divider w="full" borderColor="gray.400" />
        <Icon as={MdDragHandle} color="gray.400" w="-4" />
        <Divider w="full" borderColor="gray.400" />
      </HStack>
    </PanelResizeHandle>
  );
}

export function SidebarDraggable({
  left,
  right,
  children,
  hideNavbar,
}: {
  left?: React.ReactNode;
  right?: React.ReactNode;
  children: React.ReactNode;
  hideNavbar?: boolean;
}) {
  const ICON_SIZE = "10";
  const leftPanelRef = useRef<ImperativePanelHandle>(null);
  const rightPanelRef = useRef<ImperativePanelHandle>(null);

  const toggleLeftPanel = () => {
    const panel = leftPanelRef.current;
    if (panel) {
      if (panel.getCollapsed()) panel.expand();
      else panel.collapse();
    }
  };
  const toggleRightPanel = () => {
    const panel = rightPanelRef.current;
    if (panel) {
      if (panel.getCollapsed()) panel.expand();
      else panel.collapse();
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const activeWorkspaceId = useEditorState((s) => s.activeWorkspaceId);
  const activePromptId = useEditorState((s) => s.activePromptId);
  const activeDatasetId = useEditorState((s) => s.activeDatasetId);
  const activeHistoryId = useEditorState((s) => s.activeHistoryId);

  // This is a HACK to check tab state!
  const promptTabActive = location.pathname.includes("/prompt");
  const datasetTabActive = location.pathname.includes("/dataset");
  const settingsTabActive = location.pathname.includes("/settings");
  const historyTabActive = location.pathname.includes("/history");
  const eventTabActive = location.pathname.includes("/event");
  const dashboardTabActive = location.pathname.includes("/dashboard");

  const sideIconSize = "25px";

  return (
    <HStack height="full" spacing={0} align="stretch">
      {!hideNavbar && (
        <VStack p={2} background="whiteAlpha.900">
          <Center rounded="lg" h={12} shadow="base" w={12} bg="white">
            <Text fontWeight="bold" fontSize="sm">
              WALE
            </Text>
          </Center>
          <Divider />
          <VStack>
            <Text
              pl={1}
              align="center"
              textColor="gray.800"
              fontWeight="semibold"
              fontSize="xs"
              w="full"
            >
              Editor
            </Text>

            <Tooltip label="Prompts">
              <IconButton
                background="white"
                _hover={{
                  background: "blackAlpha.100",
                }}
                aria-label="Prompts"
                // TODO: color scheme for active tab!
                icon={<BsJournalCode size={sideIconSize} color="#19202d" />}
                onClick={() => {
                  // FROM GLOBAL URL STATE
                  if (promptTabActive) {
                    toggleLeftPanel();
                  } else {
                    leftPanelRef.current?.expand();
                    const promptPath =
                      "/" + activeWorkspaceId + "/" + PROMPT_EDITOR_ROUTE;
                    if (activePromptId) {
                      navigate(promptPath + "/" + activePromptId);
                    } else {
                      navigate(promptPath);
                    }
                  }
                }}
                w={ICON_SIZE}
                h={ICON_SIZE}
              />
            </Tooltip>
            <Tooltip label="Datasets">
              <IconButton
                _hover={{
                  background: "blackAlpha.100",
                }}
                aria-label="Datasets"
                background="white"
                icon={
                  <BsFillDatabaseFill size={sideIconSize} color="#19202d" />
                }
                onClick={() => {
                  if (datasetTabActive) {
                    toggleLeftPanel();
                  } else {
                    leftPanelRef.current?.expand();
                    const datasetPath =
                      "/" + activeWorkspaceId + "/" + DATASET_EDITOR_ROUTE;
                    if (activeDatasetId) {
                      navigate(datasetPath + "/" + activeDatasetId);
                    } else {
                      navigate(datasetPath);
                    }
                  }
                }}
                w={ICON_SIZE}
                h={ICON_SIZE}
              />
            </Tooltip>
            <Tooltip label="History">
              <IconButton
                _hover={{
                  background: "blackAlpha.100",
                }}
                aria-label="History"
                background="white"
                icon={<BsClockHistory size={sideIconSize} color="#19202d" />}
                onClick={() => {
                  if (historyTabActive) {
                    toggleLeftPanel();
                  } else {
                    leftPanelRef.current?.expand();
                    const historyPath =
                      "/" + activeWorkspaceId + "/" + HISTORY_PAGE_ROUTE;
                    if (activeHistoryId) {
                      navigate(historyPath + "/" + activeHistoryId);
                    } else {
                      navigate(historyPath);
                    }
                  }
                }}
                w={ICON_SIZE}
                h={ICON_SIZE}
              />
            </Tooltip>
          </VStack>
          <Divider />
          <VStack>
            <Text
              pl={1}
              align="center"
              textColor="gray.800"
              fontWeight="semibold"
              fontSize="xs"
              w="full"
            >
              Reports
            </Text>

            <Tooltip label="Events">
              <IconButton
                _hover={{
                  background: "blackAlpha.100",
                }}
                aria-label="Event"
                background="white"
                icon={<BsClipboardData size={sideIconSize} color="#19202d" />}
                onClick={() => {
                  if (eventTabActive) {
                    toggleLeftPanel();
                  } else {
                    leftPanelRef.current?.expand();
                    const eventPath =
                      "/" + activeWorkspaceId + "/" + EVENT_PAGE_ROUTE;
                    navigate(eventPath);
                  }
                }}
                w={ICON_SIZE}
                h={ICON_SIZE}
              />
            </Tooltip>

            <Tooltip label="Dashboard">
              <IconButton
                _hover={{
                  background: "blackAlpha.100",
                }}
                aria-label="Dashboard"
                background="white"
                icon={<BsSpeedometer2 size={sideIconSize} color="#19202d" />}
                onClick={() => {
                  if (dashboardTabActive) {
                    toggleLeftPanel();
                  } else {
                    leftPanelRef.current?.expand();
                    const dashboardPath =
                      "/" + activeWorkspaceId + "/" + DASHBOARD_PAGE_ROUTE;
                    navigate(dashboardPath);
                  }
                }}
                w={ICON_SIZE}
                h={ICON_SIZE}
              />
            </Tooltip>
          </VStack>

          <Spacer />
          <Divider />
          <IconButton
            size="xl"
            background="white"
            aria-label="Settings"
            _hover={{
              background: "blackAlpha.100",
            }}
            icon={<IoMdSettings size={sideIconSize} />}
            onClick={() => {
              const settingsPath =
                "/" + activeWorkspaceId + "/" + SETTINGS_ROUTE;
              navigate(settingsPath);
            }}
            w={ICON_SIZE}
            h={ICON_SIZE}
          />
        </VStack>
      )}

      {/* ISSUE: this is a percentage! */}
      <PanelGroup direction="horizontal" autoSaveId="persistence">
        {left && (
          <>
            <Panel
              collapsible
              defaultSize={25}
              minSize={20}
              maxSize={30}
              ref={leftPanelRef}
            >
              {left}
            </Panel>
            <ResizeHandleVertical />
          </>
        )}
        <Panel>{children}</Panel>
        {right && (
          <>
            <ResizeHandleVertical />
            <Panel
              collapsible
              defaultSize={25}
              minSize={15}
              maxSize={50}
              ref={rightPanelRef}
            >
              {right}
            </Panel>
          </>
        )}
      </PanelGroup>
    </HStack>
  );
}
