import { HStack, Text, VStack, Heading, Stack, Link } from "@chakra-ui/react";
import { OverflowWrapper } from "../components/OverflowWrapper";
import { SidebarDraggable } from "./editor/Sidebars";
import { useParams } from "react-router-dom";
import {
  BsDiscord,
  BsFillFileEarmarkTextFill,
  BsTwitter,
  BsFillEnvelopeAtFill,
} from "react-icons/bs";

const InnerDashboard = ({ workspaceId }: { workspaceId: string }) => {
  return (
    <SidebarDraggable>
      <OverflowWrapper>
        <VStack
          flex="1"
          w="full"
          h="full"
          spacing={0}
          px={8}
          py={5}
          borderLeftWidth={1}
        >
          <HStack w="full" align="center">
            <VStack w="full" align="flex-start">
              <Heading size="lg">Dashboard</Heading>
              <Text fontSize="md">Analytics on your events</Text>
            </VStack>
          </HStack>

          {/* reserved for toolbar (filter, search by keyword, etc) */}
          {/* <HStack>
            <Button>Filter</Button>
          </HStack> */}
          <Stack flex="1" w="full" align="center">
            <Text
              bgGradient="linear(to-r, blue.600, #FF0080)"
              bgClip="text"
              fontSize="6xl"
              fontWeight="extrabold"
              mt={12}
            >
              Dashboard Analytics Coming Soon!
            </Text>
            <Text
              color="gray.600"
              bgGradient="linear(to-tl, blackAlpha.600, gray.900)"
              bgClip="text"
              fontSize="3xl"
              fontWeight="semibold"
              pt={32}
            >
              Join the waitlist for access
            </Text>

            <VStack
              pt={5}
              alignSelf="center"
              color="black"
              fontSize="md"
              fontWeight="normal"
            >
              <Link
                bg="green.600"
                borderRadius="md"
                bgGradient="linear(to-r, teal.500, green.500)"
                _hover={{
                  bgGradient: "linear(to-r, emeral.500, yellow.600)",
                  textDecoration: "default",
                }}
                p={2}
                rounded="md"
                href="https://forms.gle/hV7YKHQG3Wc1k7x96"
                textColor="white"
                fontSize="2xl"
                fontWeight="bold"
                px={5}
              >
                Join now
              </Link>

              <VStack pt={48}>
                <Text fontWeight="medium" fontSize="md" textColor="gray.700">
                  Follow our socials
                </Text>
                <HStack pt={3}>
                  <Link
                    bg="#00acee"
                    p={2}
                    rounded="xl"
                    bgGradient="linear(to-r, #00acee, blue.500)"
                    href="https://twitter.com/trywale"
                  >
                    <BsTwitter color="white" size="30px" />
                  </Link>
                  <Link
                    bg="#"
                    p={2}
                    rounded="xl"
                    bgGradient="linear(to-l, #7289d9, purple.500)"
                    href="https://discord.gg/nRUTD8yQeP"
                  >
                    <BsDiscord color="white" size="30px" />
                  </Link>

                  <Link
                    bg="red.600"
                    p={2}
                    rounded="xl"
                    bgGradient="linear(to-r, rose.600, red.700)"
                    href="mailto:trywale@gmail.com"
                  >
                    <BsFillEnvelopeAtFill color="white" size="30px" />
                  </Link>
                </HStack>
              </VStack>
            </VStack>
          </Stack>
        </VStack>
      </OverflowWrapper>
    </SidebarDraggable>
  );
};
const DashboardPage = () => {
  const params = useParams();
  const workspaceId = params.workspaceId as string;

  return (
    <VStack
      height="100vh"
      spacing={0}
      align="stretch"
      bgGradient="linear(to-r, white, whiteAlpha.900)"
    >
      <InnerDashboard workspaceId={workspaceId} />
    </VStack>
  );
};

export default DashboardPage;
