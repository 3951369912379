import React from "react";

import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Button,
  VStack,
  Box,
  Text,
  Link as ChakraLink,
  Grid,
  GridItem,
  Select,
} from "@chakra-ui/react";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import { string } from "yup";
import * as Yup from "yup";
import CompanyEmailValidator from "company-email-validator";

import { useUserContext } from "./UserContext";
import { doc, updateDoc } from "firebase/firestore";
import firebase from "../firebase";

interface Department {
  id: string;
  name: string;
}

const departments: Department[] = [
  { id: "MARKETING", name: "Marketing" },
  { id: "HR_LEGAL", name: "HR & Legal" },
  { id: "PRODUCT_DESIGN", name: "Product & Design" },
  { id: "CREATIVE_PRODUCTION", name: "Creative Production" },
  { id: "ENGINEERING", name: "Engineering" },
  { id: "CUSTOMER_SERVICE", name: "Customer Service" },
  { id: "OPERATIONS", name: "Operations" },
  { id: "FINANCE", name: "Finance" },
  { id: "IT_SUPPORT", name: "IT & Support" },
  { id: "MANUFACTURING", name: "Manufacturing" },
  { id: "SALES_ACCOUNT_MGMT", name: "Sales & Account Mgmt." },
  { id: "OTHER_PERSONAL", name: "Other / Personal" },
];

export interface ProviderRegistrationFormValues {
  firstName: string;
  lastName: string;
  companyEmail: string;
  department: string;
  openAIApiKey: string;
}

declare module 'yup' {
  interface StringSchema {
    companyEmail(message?: string): StringSchema;
  }
}

Yup.addMethod(Yup.string, 'companyEmail', function (message) {
  return this.test('company-email', message, function (value) {
    if (!value) {
      return true; // Empty values are validated separately
    }
    return CompanyEmailValidator.isCompanyEmail(value);
  });
});

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  companyEmail: Yup.string().email("Invalid email").companyEmail("Use your company email").required("Required"),
  department: Yup.string().required("Required"),
  openAIApiKey: Yup.string().required("Required"),
});

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export type ProviderRegistrationFormProps = {
  userOpenAIApiKey?: string;
  onSubmit: (
    values: ProviderRegistrationFormValues,
    helpers: FormikHelpers<ProviderRegistrationFormValues>
  ) => Promise<void>;
};

export const ProviderRegistrationForm = ({
  onSubmit,
}: ProviderRegistrationFormProps) => {
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        companyEmail: "",
        department: "",
        openAIApiKey: "",
      }}
      validationSchema={SignupSchema}
      onSubmit={onSubmit}
    >
      {({
        errors,
        touched,
        isSubmitting,
      }: FormikProps<ProviderRegistrationFormValues>) => (
        <Form>
          <Grid templateColumns="repeat(2, 1fr)" gap={3}>
            <GridItem colSpan={1}>
              <Field name="firstName">
                {({
                  field,
                  form,
                }: {
                  field: any;
                  form: FormikProps<ProviderRegistrationFormValues>;
                }) => (
                  <FormControl
                    id="first-name"
                    isInvalid={!!(errors.firstName && touched.firstName)}
                  >
                    <FormLabel mb={1}>First Name</FormLabel>
                    <Input {...field} type="text" />
                    <FormErrorMessage>{form.errors.firstName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={1}>
              <Field name="lastName">
                {({
                  field,
                  form,
                }: {
                  field: any;
                  form: FormikProps<ProviderRegistrationFormValues>;
                }) => (
                  <FormControl
                    id="last-name"
                    isInvalid={!!(errors.lastName && touched.lastName)}
                  >
                    <FormLabel mb={1}>Last Name</FormLabel>
                    <Input {...field} type="text" />
                    <FormErrorMessage>{form.errors.lastName}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={2}>
              <Field name="companyEmail">
                {({
                  field,
                  form,
                }: {
                  field: any;
                  form: FormikProps<ProviderRegistrationFormValues>;
                }) => (
                  <FormControl
                    id="company-email"
                    isInvalid={!!(errors.companyEmail && touched.companyEmail)}
                  >
                    <FormLabel mb={1}>Company Email</FormLabel>
                    <Input {...field} type="email" />
                    <FormErrorMessage>
                      {form.errors.companyEmail}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={2}>
              <Field name="department">
                {({
                  field,
                  form,
                }: {
                  field: any;
                  form: FormikProps<ProviderRegistrationFormValues>;
                }) => (
                  <FormControl
                    id="department"
                    isInvalid={!!(errors.department && touched.department)}
                  >
                    <FormLabel>Department</FormLabel>
                    <Select {...field} placeholder="Select Department">
                      {departments.map((department) => (
                        <option key={department.id} value={department.id}>
                          {department.name}
                        </option>
                      ))}
                    </Select>
                    <FormErrorMessage>{form.errors.department}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={2}>
              <Field name="openAIApiKey">
                {({
                  field,
                  form,
                }: {
                  field: any;
                  form: FormikProps<ProviderRegistrationFormValues>;
                }) => (
                  <FormControl
                    id="openai-api-key"
                    isInvalid={!!(errors.openAIApiKey && touched.openAIApiKey)}
                  >
                    <FormLabel mb={1}>OpenAI API Key</FormLabel>
                    <Input {...field} type="text" />
                    <Text fontSize="xs" pt={0.5}>
                      We will need your{"  "}
                      <ChakraLink
                        color="brand.500"
                        href={"https://beta.openai.com/account/api-keys"}
                        isExternal
                      >
                        OpenAI API key
                      </ChakraLink>{" "}
                      to help you send queries.
                    </Text>
                    <FormErrorMessage>
                      {form.errors.openAIApiKey}
                    </FormErrorMessage>

                  </FormControl>
                )}
              </Field>
            </GridItem>
            <GridItem colSpan={2}>
              <Button
                mt={4}
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
                disabled={Object.keys(errors).length !== 0}
              >
                Submit
              </Button>
            </GridItem>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default ProviderRegistrationForm;

