import {
  Button,
  Text,
  List,
  ListItem,
  HStack,
  ListIcon,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import { ColumnFilter } from "@tanstack/react-table";

import {
  BsCursorFill,
  BsInputCursorText,
  BsCardList,
  BsFillPersonFill,
  BsClock,
  BsCurrencyDollar,
  BsLink45Deg,
} from "react-icons/bs";

interface FilterPopoverContentProps {
  onClose: () => void;
  handleCreateFilter: (filter: ColumnFilter) => void;
}

const FilterPopoverContent = ({
  onClose,
  handleCreateFilter,
}: FilterPopoverContentProps) => {
  return (
    <PopoverContent>
      <PopoverBody>
        <List spacing={0}>
          <ListItem>
            <Button
              w="full"
              justifyContent="flex-start"
              bg="transparent"
              px={1}
              onClick={() => {
                handleCreateFilter({ id: "id", value: "" });
                onClose();
              }}
            >
              <HStack>
                <ListIcon as={BsCursorFill} color="emerald.700" />
                <Text fontWeight="medium">event_id</Text>
              </HStack>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              w="full"
              justifyContent="flex-start"
              bg="transparent"
              px={1}
              onClick={() => {
                handleCreateFilter({ id: "inputs", value: "" });
                onClose();
              }}
            >
              <HStack>
                <ListIcon as={BsInputCursorText} color="emerald.700" />
                <Text fontWeight="medium">input</Text>
              </HStack>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              w="full"
              justifyContent="flex-start"
              bg="transparent"
              px={1}
              onClick={() => {
                handleCreateFilter({ id: "output", value: "" });
                onClose();
              }}
            >
              <HStack>
                <ListIcon as={BsCardList} color="emerald.700" />
                <Text fontWeight="medium">output</Text>
              </HStack>
            </Button>
          </ListItem>

          <ListItem>
            <Button
              w="full"
              justifyContent="flex-start"
              bg="transparent"
              px={1}
              onClick={() => {
                handleCreateFilter({ id: "person_id", value: "" });
                onClose();
              }}
            >
              <HStack>
                <ListIcon as={BsFillPersonFill} color="emerald.700" />
                <Text fontWeight="medium">person_id</Text>
              </HStack>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              w="full"
              justifyContent="flex-start"
              bg="transparent"
              px={1}
              onClick={() => {
                handleCreateFilter({ id: "timestamp", value: "" });

                onClose();
              }}
            >
              <HStack>
                <ListIcon as={BsClock} color="emerald.700" />
                <Text fontWeight="medium">time</Text>
              </HStack>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              w="full"
              justifyContent="flex-start"
              bg="transparent"
              px={1}
              onClick={() => {
                handleCreateFilter({ id: "total_tokens", value: "" });
                onClose();
              }}
            >
              <HStack>
                <ListIcon as={BsCurrencyDollar} color="emerald.700" />
                <Text fontWeight="medium">total_tokens</Text>
              </HStack>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              w="full"
              justifyContent="flex-start"
              bg="transparent"
              px={1}
              onClick={() => {
                handleCreateFilter({ id: "task_id", value: "" });
                onClose();
              }}
            >
              <HStack>
                <ListIcon as={BsLink45Deg} color="emerald.700" />
                <Text fontWeight="medium">task_id</Text>
              </HStack>
            </Button>
          </ListItem>
        </List>
      </PopoverBody>
    </PopoverContent>
  );
};
export default FilterPopoverContent;
