import React from 'react';
import { Navigate, Outlet } from "react-router-dom";
import LoadingPage from "../pages/LoadingPage";
import { useUserContext } from "./UserContext";
import { computeAuthRoute } from "./utils";


// Determines invariants
export const useUser = () => {
  const { user, firebaseUser, signOut, refreshUser } = useUserContext();
  if (user == null || firebaseUser == null) {
    throw Error('user or firebaseUser is null. Use within <ProtectedRoute />')
  }

  return {
    user,
    firebaseUser,
    signOut,
    refreshUser,
  }
}


export const ProtectedRoute: React.FC = () => {
  const { firebaseUser, user, loading, setAuthDest } = useUserContext();

  if (loading) {
    return <LoadingPage />
  }

  const path = computeAuthRoute(firebaseUser, user);

  if (path !== null) {
    // Init auth start-session
    return <Navigate to={path} replace />
  }

  return (
    <Outlet />
  );
};

