import { HStack, Text, VStack, Heading, Stack } from "@chakra-ui/react";
import { useEditorContext } from "./editor/EditorRoute";
import { OverflowWrapper } from "../components/OverflowWrapper";
import { SidebarDraggable } from "./editor/Sidebars";
import { PromptGeneration } from "../types";
import EventTable from "../components/EventTable";
import { useParams } from "react-router-dom";

const InnerEvent = ({ workspaceId }: { workspaceId: string }) => {
  const onRefresh = () => {
    console.log("refreshed");
  };
  // const reRender = React.useReducer(() => ({}), {})[1];
  return (
    <SidebarDraggable>
      <OverflowWrapper>
        <VStack
          flex="1"
          w="full"
          h="full"
          spacing={0}
          px={8}
          py={5}
          borderLeftWidth={1}
        >
          <HStack w="full" align="center">
            <VStack w="full" align="flex-start">
              <Heading size="lg">Events</Heading>
              <Text fontSize="md">Monitor logged events</Text>
            </VStack>
          </HStack>

          {/* reserved for toolbar (filter, search by keyword, etc) */}
          {/* <HStack>
            <Button>Filter</Button>
          </HStack> */}
          <Stack flex="1" w="full">
            <EventTable workspaceId={workspaceId} />
          </Stack>
        </VStack>
      </OverflowWrapper>
    </SidebarDraggable>
  );
};
const EventPage = () => {
  const params = useParams();
  const workspaceId = params.workspaceId as string;

  return (
    <VStack height="100vh" spacing={0} align="stretch">
      <InnerEvent workspaceId={workspaceId} />
    </VStack>
  );
};

export default EventPage;
