import {
  BsCardList,
  BsClock,
  BsCurrencyDollar,
  BsCursorFill,
  BsFillPersonFill,
  BsInputCursorText,
  BsLink45Deg,
} from "react-icons/bs";

interface FilterIconSelectProps {
  id: string;
  color: string;
  size: number;
}

const FilterIconSelect = ({ id, color, size }: FilterIconSelectProps) => {
  switch (id) {
    case "id":
      return <BsCursorFill color={color} size={size} />;

    case "inputs":
      return <BsInputCursorText color={color} size={size} />;

    case "output":
      return <BsCardList color={color} size={size} />;

    case "person_id":
      return <BsFillPersonFill color={color} size={size} />;

    case "timestamp":
      return <BsClock color={color} size={size} />;

    case "total_tokens":
      return <BsCurrencyDollar color={color} size={size} />;

    case "task_id":
      return <BsLink45Deg color={color} size={size} />;

    default:
      return <div></div>;
  }
};

export default FilterIconSelect;
