import React, { useEffect, useRef, useState } from "react";

import {
  Column,
  Table,
  useReactTable,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  getPaginationRowModel,
  sortingFns,
  getSortedRowModel,
  FilterFn,
  SortingFn,
  ColumnDef,
  flexRender,
  FilterFns,
  ColumnResizeMode,
  ColumnFilter,
} from "@tanstack/react-table";

import {
  RankingInfo,
  rankItem,
  compareItems,
} from "@tanstack/match-sorter-utils";

import {
  Button,
  HStack,
  Input,
  Table as ChTable,
  TableContainer,
  VStack,
  Text,
  Th,
  Thead,
  Tr,
  Tbody,
  Td,
  Divider,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverTrigger,
  useDisclosure,
  Spinner,
  PopoverBody,
  PopoverContent,
  useToast,
  Switch,
} from "@chakra-ui/react";
import { HiOutlineRefresh } from "react-icons/hi";
import { HiChevronUpDown } from "react-icons/hi2";
import { BsSearch, BsXLg } from "react-icons/bs";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import FilterPopoverContent from "./FilterPopoverContent";
import { Event } from "../types";
import TimeAgo from "react-timeago";
import FilterIconSelect from "./FilterIconSelec";

import firebase from "../firebase";
import {
  collection,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import posthog from "posthog-js";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value);

  // Store the itemRank info
  addMeta({
    itemRank,
  });

  // Return if the item should be filtered in/out
  return itemRank.passed;
};

const fuzzySort: SortingFn<any> = (rowA, rowB, columnId) => {
  let dir = 0;

  // Only sort by rank if the column has ranking information
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(
      rowA.columnFiltersMeta[columnId]?.itemRank!,
      rowB.columnFiltersMeta[columnId]?.itemRank!
    );
  }

  // Provide an alphanumeric fallback for when the item ranks are equal
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

interface EventTableProps {
  workspaceId: string;
}

const EventTable = ({ workspaceId }: EventTableProps) => {
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = React.useState("");

  const columns = React.useMemo<ColumnDef<Event, any>[]>(
    () => [
      // {
      //   accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      //   id: "fullName",
      //   header: "Full Name",
      //   cell: (info) => info.getValue(),
      //   footer: (props) => props.column.id,
      //   filterFn: "fuzzy",
      //   sortingFn: fuzzySort,
      // },
      {
        accessorKey: "id",
        header: () => "Event",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "person_id",
        header: () => "Person",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "inputs",
        header: "Input",
        cell: (input) => (
          <Text
            isTruncated
            noOfLines={[1, 2]}
            wordBreak={"break-all"}
            whiteSpace={"normal"}
          >
            {JSON.stringify(input.getValue())}
          </Text>
        ),

        footer: (props) => props.column.id,
      },
      {
        accessorKey: "output",
        header: "Output",
        cell: (output) => (
          <Text
            isTruncated
            noOfLines={[1, 2]}
            wordBreak={"break-all"}
            whiteSpace={"normal"}
          >
            {output.getValue()}
          </Text>
        ),
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "total_tokens",
        header: "Total Tokens",
        cell: (tokens) => <Text pl={5}>{tokens.getValue()}</Text>,
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "task_id",
        header: "Task",
        footer: (props) => props.column.id,
      },
      {
        accessorKey: "timestamp",
        header: "Time",
        cell: (time) => <TimeAgo date={time.getValue().toDate()} />,
        footer: (props) => props.column.id,
      },
    ],
    []
  );

  const [data, setData] = useState<Event[]>([]);

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
  });

  // const [page, setPage] = useState<number>(
  //   table.getState().pagination.pageIndex + 1
  // );
  const pageSize = 20;
  useEffect(() => {
    table.setPageSize(100);
  }, [pageSize]);
  //const refreshData = () => setData((old) => makeData(10));
  const [eventsLoading, setEventsLoading] = useState<boolean>(true);
  const [lastEvent, setLastEvent] = useState<any>(null);
  const [hasMoreEvents, setHasMoreEvents] = useState<boolean>(true);
  const hasMoreEventsRef = useRef(true);
  const [isLiveUpdatesEnabled, setIsLiveUpdatesEnabled] =
    useState<boolean>(true);

  const eventsRef = collection(
    firebase.db,
    "workspaces",
    workspaceId,
    "events"
  );

  const eventsQuery = query(
    eventsRef,
    orderBy("timestamp", "desc"),
    limit(pageSize)
  );

  useEffect(() => {
    let unsubscribe: (() => void) | undefined = undefined;
    try {
      if (isLiveUpdatesEnabled) {
        unsubscribe = onSnapshot(eventsQuery, (snapshot) => {
          const newEvents = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Event[];
          setData(newEvents);
          setLastEvent(snapshot.docs[snapshot.docs.length - 1]);
          hasMoreEventsRef.current = snapshot.docs.length === pageSize;
          setHasMoreEvents(hasMoreEventsRef.current);
          setEventsLoading(false);
        });
      }
    } catch (error) {
      posthog?.capture("Error in onSnapshot @useEffect in EventTable", {
        errorMsg: error,
      });
      console.error("Error in onSnapshot @useEffect in EventTable:", error);
    }
    return () => {
      try {
        if (unsubscribe) {
          unsubscribe();
        }
      } catch (error) {
        posthog?.capture("Error in unsubscribe @useEffect in EventTable", {
          errorMsg: error,
        });
        console.error("Error in unsubscribe @useEffect in EventTable:", error);
      }
    };
  }, [isLiveUpdatesEnabled]);

  const handleRefresh = async () => {
    posthog?.capture("handleRefresh", { property: "eventTable" });
    try {
      setIsLiveUpdatesEnabled(false);
      setEventsLoading(true);

      const snapshot = await getDocs(eventsQuery);

      const newEvents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Event[];

      setData(newEvents);
      setLastEvent(snapshot.docs[snapshot.docs.length - 1]);
      hasMoreEventsRef.current = snapshot.docs.length === pageSize;
      setHasMoreEvents(hasMoreEventsRef.current);
      setEventsLoading(false);
    } catch (error) {
      posthog?.capture("Error in handleRefresh in EventTable", {
        errorMsg: error,
      });
      console.error("Error in handleRefresh in EventTable");
    }
  };

  const loadMore = async () => {
    posthog?.capture("loadMore", {
      property: "eventTable",
    });
    try {
      const eventsQueryNext = query(
        eventsRef,
        orderBy("timestamp", "desc"),
        startAfter(lastEvent),
        limit(pageSize)
      );

      const snapshot = await getDocs(eventsQueryNext);

      const newEvents = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Event[];
      setData([...data, ...newEvents]);
      setLastEvent(snapshot.docs[snapshot.docs.length - 1]);
      hasMoreEventsRef.current = snapshot.docs.length === pageSize;
      setHasMoreEvents(hasMoreEventsRef.current);
    } catch (error) {
      posthog?.capture("Error @loadMore", { errorMsg: error });
      console.error("Error @loadMore", error);
    }
  };

  // React.useEffect(() => {
  //   if (table.getState().columnFilters[0]?.id === "fullName") {
  //     if (table.getState().sorting[0]?.id !== "fullName") {
  //       table.setSorting([{ id: "fullName", desc: false }]);
  //     }
  //   }
  // }, [table.getState().columnFilters[0]?.id]);

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   table.setPageIndex(page);
  // };

  const handleCreateFilter = (filter: ColumnFilter) => {
    posthog?.capture("handleCreateFilter", {
      property: filter.id,
    });
    if (activatedFilters.some((item) => item.id == filter.id)) {
      toast({
        title: "Filter already exists",
        position: "top",
        status: "error",
        isClosable: true,
      });
    } else {
      setActivatedFilters([
        ...activatedFilters,
        { id: filter.id, value: filter.value },
      ]);
    }
  };

  //console.log("table state:", table.getState());
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [newFilter, setNewFilter] = useState<string | number>("");
  const [activatedFilters, setActivatedFilters] = useState<ColumnFiltersState>(
    []
  );

  const toast = useToast();

  return (
    <VStack w="full">
      <Divider borderColor={"blackAlpha.300"} mt={5} />
      <HStack
        h="fit-content"
        w="full"
        alignItems="center"
        justify="flex-start"
        flexWrap="wrap"
        gap={1}
      >
        <DebouncedInput
          value={globalFilter ?? ""}
          onChange={(value) => setGlobalFilter(String(value))}
          placeholder="Search all columns..."
          height={"40px"}
          width={56}
          leftIcon={<BsSearch />}
        />

        {activatedFilters.map((filter: ColumnFilter) => {
          return (
            <Popover offset={[90, 8]}>
              <HStack
                rounded="lg"
                bg="gray.100"
                _hover={{
                  cursor: "pointer",
                }}
              >
                <PopoverTrigger>
                  <HStack pl={1}>
                    <FilterIconSelect
                      id={filter.id}
                      color="emerald.700"
                      size={15}
                    />
                    <Text fontSize="sm" fontWeight="medium">
                      {filter.id +
                        " = " +
                        columnFilters.find((item) => item.id === filter.id)
                          ?.value ?? "?"}
                    </Text>
                  </HStack>
                </PopoverTrigger>

                <Button
                  p={0}
                  size="sm"
                  onClick={() => {
                    posthog?.capture("removed Filter", {
                      property: filter.id,
                    });
                    table.getHeaderGroups().map((headerGroup) =>
                      headerGroup.headers.map((header) => {
                        const column = header.column;
                        if (column.id === filter.id) {
                          const removed = columnFilters.filter(
                            (item) => item.id != filter.id
                          );
                          const activatedRemoved = activatedFilters.filter(
                            (item) => item.id != filter.id
                          );
                          setColumnFilters(removed);
                          setActivatedFilters(activatedRemoved);
                        }
                      })
                    );
                  }}
                >
                  <BsXLg />
                </Button>
              </HStack>

              <PopoverContent>
                <PopoverBody>
                  <HStack justify="space-between">
                    <Text fontSize="sm"> {filter.id}</Text>
                    <Button fontSize="sm">=</Button>
                    {/* <form onSubmit={(e) => handleFilterUpdate(e, filter)}>
                      <Input
                        placeholder="Enter value..."
                        _hover={{
                          borderColor: "blue.600",
                          borderWidth: 1,
                        }}
                        onChange={(e) => setNewFilter(e.target.value)}
                      />
                    </form> */}
                    <Filter
                      column={
                        table
                          .getHeaderGroups()
                          .flatMap((headerGroup) =>
                            headerGroup.headers
                              .filter(
                                (header) => header.column.id === filter.id
                              )
                              .map((header) => header.column)
                          )[0]
                      }
                      height="30px"
                      table={table}
                    />
                  </HStack>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          );
        })}

        <Popover offset={[110, 5]} isOpen={isOpen} onClose={onClose}>
          <PopoverTrigger>
            <Button
              _hover={{
                bg: "blue.100",
                borderColor: "blue.600",
              }}
              px={2}
              fontSize="sm"
              textColor="blue.700"
              bg={isOpen ? "blue.100" : "white"}
              borderColor={isOpen ? "blue.600" : "blackAlpha.300"}
              borderWidth={1}
              onClick={onToggle}
            >
              + Add Filter
            </Button>
          </PopoverTrigger>
          <FilterPopoverContent
            handleCreateFilter={handleCreateFilter}
            onClose={onClose}
          />
        </Popover>
      </HStack>
      <Divider borderColor={"blackAlpha.300"} />
      <HStack alignSelf="flex-start">
        <HStack borderWidth={1} p={2} rounded="lg">
          <Text fontWeight="semibold" fontSize="sm">
            Listen to live events
          </Text>
          <Switch
            size="md"
            onChange={() => setIsLiveUpdatesEnabled(!isLiveUpdatesEnabled)}
            isChecked={isLiveUpdatesEnabled}
          />
        </HStack>
        {!isLiveUpdatesEnabled && (
          <Button
            variant="solid"
            color="white"
            bg="blue.600"
            _hover={{
              background: "blue.700",
            }}
            size="sm"
            onClick={handleRefresh}
          >
            <HStack>
              <HiOutlineRefresh /> <Text>Refresh</Text>
            </HStack>
          </Button>
        )}
      </HStack>

      {eventsLoading ? (
        <Spinner color="blue.600" />
      ) : (
        <TableContainer w="full">
          <ChTable border="1px" borderColor="gray.300">
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id} bg="blackAlpha.100">
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        py={1.5}
                        borderColor="gray.300"
                        key={header.id}
                        colSpan={header.colSpan}
                        textColor="black"
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <Button
                              fontSize="sm"
                              px={1}
                              bg="transparent"
                              _hover={{
                                background: "gray.200",
                              }}
                              {...{
                                className: header.column.getCanSort()
                                  ? "cursor-pointer select-none"
                                  : "",
                                onClick:
                                  header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                              {{
                                asc: " 🔼",
                                desc: " 🔽",
                              }[header.column.getIsSorted() as string] ?? (
                                <HiChevronUpDown size={22} />
                              )}
                            </Button>
                            {/* {header.column.getCanFilter() ? (
                              <div>
                                <Filter column={header.column} table={table} />
                              </div>
                            ) : null} */}
                          </>
                        )}
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id} borderColor="gray.300">
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </ChTable>
        </TableContainer>
      )}

      <div className="h-2" />
      <HStack gap={2} justify="center" w="full" pb={10}>
        {/* <HStack>
          <Select
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        </HStack>

        <HStack>
          <HStack className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>
          </HStack>
          <HStack className="flex items-center gap-1">
            <Text w="fit-content"> | Go to page:</Text>
            <form onSubmit={handleSubmit}>
              <Input
                p={1}
                rounded="md"
                w={16}
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  setPage(e.target.value ? Number(e.target.value) - 1 : 0);
                }}
              />
            </form>
          </HStack>
        </HStack> */}
        <HStack>
          {/* <Button
            variant="solid"
            color="white"
            bg="blue.600"
            size="sm"
            _hover={{
              background: "blue.700",
            }}
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <FiChevronLeft />
          </Button> */}
          <Button
            variant="solid"
            color="white"
            bg="blue.600"
            _hover={{
              background: "blue.700",
            }}
            size="md"
            onClick={loadMore}
            isDisabled={!hasMoreEvents}
          >
            Load more
          </Button>
        </HStack>
      </HStack>
      {/* <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
      <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div>
      <div>
        <button onClick={() => refreshData()}>Refresh Data</button>
      </div>
       */}
      {/* <pre>{JSON.stringify(table.getState(), null, 2)}</pre> */}
    </VStack>
  );
};

function Filter({
  column,
  table,
  height,
}: {
  column: Column<any, unknown>;
  table: Table<any>;
  height?: string;
}) {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = React.useMemo(
    () =>
      typeof firstValue === "number"
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()]
  );

  return typeof firstValue === "number" ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[0] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
          width={24}
          height={height}
        />
        <DebouncedInput
          type="number"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? "")}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? "")}
          value={(columnFilterValue as [number, number])?.[1] ?? ""}
          onChange={(value) =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ""
          }`}
          className="w-24 border shadow rounded"
          width={24}
          height={height}
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={column.id + "list"}>
        {sortedUniqueValues.slice(0, 5000).map((value: any) => (
          <option value={value} key={value} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={(columnFilterValue ?? "") as string}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-36 border shadow rounded"
        width={36}
        rounded="md"
        height={height}
        list={column.id + "list"}
      />
      <div className="h-1" />
    </>
  );
}

// A debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  width,
  placeholder,
  rounded,
  height,
  leftIcon,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
  width?: number;
  placeholder?: string;
  rounded?: string;
  height?: string;
  leftIcon?: React.ReactNode;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = React.useState(initialValue);

  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  return (
    <InputGroup width={width}>
      {leftIcon && (
        <InputLeftElement pointerEvents="none" children={leftIcon} />
      )}
      <Input
        height={height || ""}
        placeholder={placeholder}
        border="1px"
        borderColor="blackAlpha.300"
        rounded="md"
        size="sm"
        fontSize="sm"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </InputGroup>
  );
}

export default EventTable;
