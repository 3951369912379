import React from "react";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

// Create a store with activePromptId and activeDatasetId using zustand
// Create a hook that returns the store

type EditorState = {
  activePromptId: string | null;
  activeDatasetId: string | null;
  activeHistoryId: string | null;
  activeWorkspaceId: string | null;
};

type EditorAction = {
  updateActivePromptId: (id: string | null) => void;
  updateActiveDatasetId: (id: string | null) => void;
  updateActiveHistoryId: (id: string | null) => void;
  updateActiveWorkspaceId: (id: string | null) => void;
};

export const useEditorState = create(
  immer<EditorState & EditorAction>((set) => ({
    activePromptId: null,
    activeDatasetId: null,
    activeHistoryId: null,
    activeWorkspaceId: null,
    updateActivePromptId: (id) =>
      set((s) => {
        s.activePromptId = id;
      }),
    updateActiveDatasetId: (id) =>
      set((s) => {
        s.activeDatasetId = id;
      }),
    updateActiveHistoryId: (id) =>
      set((s) => {
        s.activeHistoryId = id;
      }),
    updateActiveWorkspaceId: (id) =>
      set((s) => {
        s.activeWorkspaceId = id;
      }),
  }))
);
