import { initializeApp } from "firebase/app";
import {
  doc,
  query,
  where,
  collection,
  getFirestore,
  getDocs,
  setDoc,
} from "firebase/firestore";

import {
  signOut,
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import posthog from "posthog-js";

const prodConfig = {
  apiKey: "AIzaSyCOuFJp7-zcdcTBpzqF9TaN3mH7_PMLWWU",
  authDomain: "wale-ide-prod.firebaseapp.com",
  projectId: "wale-ide-prod",
  storageBucket: "wale-ide-prod.appspot.com",
  messagingSenderId: "42355909739",
  appId: "1:42355909739:web:a564ece5cf2e68c70a3d2e",
};

const devConfig = {
  apiKey: "AIzaSyAPUtabdthRm20Q2as-F_9F-g32hnksjMo",
  authDomain: "wale-ide-dev.firebaseapp.com",
  projectId: "wale-ide-dev",
  storageBucket: "wale-ide-dev.appspot.com",
  messagingSenderId: "1057727299499",
  appId: "1:1057727299499:web:c5a24ba92c9398a88b1cee",
};

const activeConfig =
  process.env.NODE_ENV === "production" ? prodConfig : devConfig;
const app = initializeApp(activeConfig);

console.log(`NODE_ENV (${process.env.NODE_ENV}): ${activeConfig.projectId}`);
const auth = getAuth();
const db = getFirestore(app);
const functions = getFunctions(app);
// Use the emulator in development

if (process.env.NODE_ENV === "development") {
  connectFunctionsEmulator(functions, "localhost", 5001);
}

const googleProvider = new GoogleAuthProvider();
const userRef = collection(db, "users");

const signInWithGoogle = async () => {
  posthog.capture("Sign in w Google", { property: "auth" });
  try {
    const response = await signInWithPopup(auth, googleProvider);
    const user = response.user;
    const q = query(userRef, where("uid", "==", user.uid));
    const snap = await getDocs(q);
    if (snap.docs.length === 0) {
      await setDoc(doc(userRef, user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        apiKey: "",
      });
    }
  } catch (error) {
    posthog.capture("Error @signInWithGoogle", {
      property: "auth",
      errorMsg: error,
    });
    console.log(error);
  }
};

const logOut = () => {
  posthog.capture("logout", { property: "auth" });
  signOut(auth);
};

const firebase = {
  auth,
  db,
  signInWithGoogle,
  logOut,
  functions,
};
export default firebase;
