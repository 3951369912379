import { BrowserRouter, Route, Routes } from "react-router-dom";
import SettingsPage from "./pages/SettingsPage";
import { ProtectedRoute } from "./auth/ProtectedRoute";
import { AuthRoute } from "./auth/AuthRoute";
import { LogInPage } from "./auth/LogInPage";
import { MultiOnboardPage } from "./auth/MultiOnboardPage";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { UserProvider } from "./auth/UserContext";
import { ConfigDataProvider } from "./config/ConfigDataProvider";
import { EditorRoute } from "./pages/editor/EditorRoute";
import {
  DATASET_EDITOR_ROUTE,
  HISTORY_PAGE_ROUTE,
  PROMPT_EDITOR_ROUTE,
  EVENT_PAGE_ROUTE,
  SETTINGS_ROUTE,
  DASHBOARD_PAGE_ROUTE,
  HISTORYCOMPARE_PAGE_ROUTE,
} from "./routes";
import React, { lazy } from "react";
import { PostHogProvider } from "posthog-js/react";
import LoadingPage from "./pages/LoadingPage";
import EventPage from "./pages/EventPage";
import DashboardPage from "./pages/Dashboard";

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

export const App = () => {
  return (
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
      <ChakraProvider theme={theme}>
        {/* Feature flags */}
        <ConfigDataProvider>
          <UserProvider>
            <AppInner />
          </UserProvider>
        </ConfigDataProvider>
      </ChakraProvider>
    </PostHogProvider>
  );
};

const PromptEditorPage = lazy(() => import("./pages/editor/PromptEditorPage"));
const DatasetsEditorPage = lazy(
  () => import("./pages/editor/DatasetsEditorPage")
);
const HistoryPage = lazy(() => import("./pages/editor/HistoryPage"));
const HistoryComparePage = lazy(
  () => import("./pages/editor/HistoryComparePage")
);

const AppInner = () => {
  // Do global things here
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route path="login" element={<LogInPage />} />
          <Route path="onboard" element={<MultiOnboardPage />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path="/:workspaceId?" element={<EditorRoute />}>
            {/* <Route path={""} element={<EditorStartPage />} /> */}
            <Route
              path={PROMPT_EDITOR_ROUTE + "/:promptId?"}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <PromptEditorPage />
                </React.Suspense>
              }
            />
            <Route
              path={DATASET_EDITOR_ROUTE + "/:datasetId?"}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <DatasetsEditorPage />
                </React.Suspense>
              }
            />
            <Route
              path={HISTORY_PAGE_ROUTE + "/:historyId?"}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <HistoryPage />
                </React.Suspense>
              }
            />
            <Route
              path={HISTORYCOMPARE_PAGE_ROUTE}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <HistoryComparePage />
                </React.Suspense>
              }
            />
            <Route
              path={EVENT_PAGE_ROUTE}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <EventPage />
                </React.Suspense>
              }
            />
            <Route
              path={DASHBOARD_PAGE_ROUTE}
              element={
                <React.Suspense fallback={<LoadingPage />}>
                  <DashboardPage />
                </React.Suspense>
              }
            />
            <Route path={SETTINGS_ROUTE} element={<SettingsPage />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
