import firebase from "./firebase";
import {
  where,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  deleteDoc,
  serverTimestamp,
  arrayRemove,
  arrayUnion,
  DocumentData,
  FirestoreDataConverter,
  PartialWithFieldValue,
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentReference,
  addDoc,
  orderBy,
  limit,
  startAt,
  startAfter,
} from "firebase/firestore";
import {
  Workspace,
  Prompt,
  ModelConfig,
  Dataset,
  Event,
  HydratedBufferToken,
  OpenAITextLLMProvider,
  PromptGeneration,
  LLMProvider,
  WaleApiKey,
} from "./types";
import { Configuration, CreateCompletionResponse, OpenAIApi } from "openai";
import { useUser } from "./auth/ProtectedRoute";
import {
  useCollectionData,
  useCollectionDataOnce,
} from "react-firebase-hooks/firestore";
import _, { last } from "lodash";

const workspacesRef = collection(firebase.db, "workspaces");
const usersRef = collection(firebase.db, "users");

export type CreatePromptParams = {
  workspaceId: string;
  name: string;
  text: string;
  createdAt?: Date;
  modifiedAt?: Date;
  modelConfig?: ModelConfig;
};

// Prompt Serializer
export const promptConverter: FirestoreDataConverter<Prompt> = {
  toFirestore({
    workspaceId,
    name,
    text,
    createdAt,
    modifiedAt,
    modelConfig,
  }: PartialWithFieldValue<CreatePromptParams>): DocumentData {
    // store history of snapshots?
    return {
      workspaceId,
      name,
      text,
      createdAt,
      modifiedAt,
      modelConfig,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Prompt {
    const {
      workspaceId,
      name,
      text,
      createdAt,
      modifiedAt,
      modelConfig,
      activeDatasetId,
    } = snapshot.data(options);
    return {
      id: snapshot.id,
      workspaceId,
      name,
      text,
      activeDatasetId,
      createdAt: createdAt?.toDate() as Date,
      modifiedAt: modifiedAt?.toDate() as Date,
      modelConfig,
    };
  },
};

export type CreateWorkspaceParams = {
  name: string;
  ownerUid: string;
  ownerName: string;
  ownerEmail: string;
  memberUids: string[];
  createdAt: any;
};

export const workspaceConverter: FirestoreDataConverter<Workspace> = {
  toFirestore({
    name,
    ownerUid,
    ownerName,
    ownerEmail,
    memberUids,
    createdAt,
  }: PartialWithFieldValue<CreateWorkspaceParams>): DocumentData {
    return {
      name,
      ownerUid,
      ownerName,
      ownerEmail,
      memberUids,
      createdAt,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Workspace {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      ownerUid: data.ownerUid,
      ownerName: data.ownerName,
      ownerEmail: data.ownerEmail,
      memberUids: data.memberUids,
      name: data.name,
      createdAt: data.createdAt?.toDate() as Date,
    };
  },
};

const createWorkspace = async (params: CreateWorkspaceParams) => {
  const { name, ownerUid, ownerName, ownerEmail, memberUids, createdAt } =
    params;

  const ref = await addDoc(workspacesRef, {
    name,
    ownerUid,
    ownerName,
    ownerEmail,
    memberUids,
    createdAt,
  });
};

export type CreateDatasetParams = {
  workspaceId: string;
  name: string;
  columns: string[];
  data: any; // Storing as a map?
  createdAt: Date;
  modifiedAt: Date;
  length: number;
};

// Dataset Serializer
export const datasetConverter: FirestoreDataConverter<Dataset> = {
  toFirestore({
    workspaceId,
    name,
    columns,
    data,
    createdAt,
    modifiedAt,
    length,
  }: PartialWithFieldValue<CreateDatasetParams>): DocumentData {
    return {
      workspaceId,
      name,
      columns,
      data,
      createdAt,
      modifiedAt,
      length,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Dataset {
    const { workspaceId, name, columns, data, createdAt, modifiedAt, length } =
      snapshot.data(options);
    return {
      id: snapshot.id,
      workspaceId,
      name,
      columns,
      data,
      createdAt: createdAt?.toDate() as Date,
      modifiedAt: modifiedAt?.toDate() as Date,
      length,
    };
  },
};

export type CreatePromptGenerationParams = {
  // Every generation saves copy of dataset! (Shouldn't be too long?)
  workspaceId: string;
  prompt: Prompt;
  dataset?: Dataset;
  templateResultsTokens: HydratedBufferToken[][];
  llmResults: string[];
  llmProvider: LLMProvider;
};

export const promptGenerationConverter: FirestoreDataConverter<PromptGeneration> =
  {
    toFirestore({
      workspaceId,
      prompt,
      dataset,
      templateResultsTokens,
      llmResults,
      llmProvider,
    }: PartialWithFieldValue<CreatePromptGenerationParams>): DocumentData {
      // Serialize templateResultsTokens to Map
      let obj = {
        workspaceId,
        prompt,
        llmProvider,
        llmResults,
        createdAt: serverTimestamp(),
      } as any;
      if (dataset != null) {
        obj = {
          ...obj,
          dataset: dataset as Dataset,
        };
      }
      if (templateResultsTokens != null) {
        obj = {
          ...obj,
          templateResultsTokens: {
            ...(templateResultsTokens as HydratedBufferToken[][]),
          },
        };
      }
      return obj;
    },
    fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions
    ): PromptGeneration {
      const {
        workspaceId,
        prompt,
        dataset,
        templateResultsTokens,
        llmProvider,
        createdAt,
        llmResults,
      } = snapshot.data(options);

      templateResultsTokens as Map<number, HydratedBufferToken[]>;
      const serializedTemplateResultsTokens = new Array(
        Object.keys(templateResultsTokens).length
      )
        .fill(0)
        .map((_, i) => templateResultsTokens[i]);
      return {
        id: snapshot.id,
        workspaceId,
        prompt,
        dataset,
        templateResultsTokens: serializedTemplateResultsTokens,
        llmProvider,
        llmResults,
        createdAt: createdAt?.toDate() as Date,
      };
    },
  };

export const createPromptGeneration = async ({
  workspaceId,
  prompt,
  dataset,
  templateResultsTokens,
  llmResults,
  llmProvider,
}: CreatePromptGenerationParams) => {
  const promptGenerationRef = collection(
    workspacesRef,
    workspaceId,
    "promptGenerations"
  ).withConverter(promptGenerationConverter);
  let obj = {
    workspaceId,
    prompt,
    templateResultsTokens,
    dataset,
    llmProvider,
    llmResults,
    createdAt: serverTimestamp(),
  } as CreatePromptGenerationParams;
  const ref = await addDoc(promptGenerationRef, obj);
  return ref.id;
};

export const getPromptGeneration = async (
  workspaceId: string,
  promptGenerationId: string
): Promise<PromptGeneration> => {
  const promptGenerationRef = doc(
    workspacesRef,
    workspaceId,
    "promptGenerations",
    promptGenerationId
  ).withConverter(promptGenerationConverter);

  const snap = await getDoc(promptGenerationRef);
  if (!snap.exists()) {
    throw new Error("Prompt Generation doesn't exist: " + promptGenerationId);
  }

  return snap.data();
};

export const getWorkspace = async (workspaceId: string): Promise<Workspace> => {
  const workspaceRef = doc(workspacesRef, workspaceId).withConverter(
    workspaceConverter
  );

  const snap = await getDoc(workspaceRef);
  if (!snap.exists()) {
    throw new Error("Workspace doesn't exist: " + workspaceId);
  }

  return snap.data();
};

export const useUserWorkspaces = () => {
  const { firebaseUser } = useUser();
  return useCollectionData(
    query(
      collection(firebase.db, "workspaces"),
      where("ownerUid", "==", firebaseUser.uid)
    ).withConverter(workspaceConverter)
  );
};

export const useWorkspacePrompts = (workspaceId: string) => {
  return useCollectionData(
    query(
      collection(firebase.db, "workspaces", workspaceId, "prompts"),
      orderBy("name", "asc"),
      orderBy("createdAt", "asc")
    ).withConverter(promptConverter)
  );
};

export const useWorkspaceDatasets = (workspaceId: string) => {
  return useCollectionData(
    query(
      collection(firebase.db, "workspaces", workspaceId, "datasets"),
      orderBy("name", "asc"),
      orderBy("createdAt", "asc")
    ).withConverter(datasetConverter)
  );
};

export const useWorkspacePromptGenerations = (workspaceId: string) => {
  // order by createdAt, recent to old
  return useCollectionData(
    query(
      collection(firebase.db, "workspaces", workspaceId, "promptGenerations"),
      orderBy("createdAt", "desc")
    ).withConverter(promptGenerationConverter)
  );
};
const DEFAULT_MODEL_CONFIG: ModelConfig = {
  model: "text-davinci-003",
  temperature: 0.5,
  maxLength: 100,
};
export const createPrompt = async ({
  workspaceId,
  name,
  text,
  activeDatasetId,
}: {
  workspaceId: string;
  name: string;
  text?: string;
  activeDatasetId?: string | null;
}): Promise<DocumentReference<DocumentData>> => {
  const promptsRef = collection(
    firebase.db,
    "workspaces",
    workspaceId,
    "prompts"
  );
  const newDocRef = await addDoc(promptsRef, {
    name,
    workspaceId: workspaceId,
    text: text ?? "",
    modifiedAt: serverTimestamp(),
    createdAt: serverTimestamp(),
    modelConfig: DEFAULT_MODEL_CONFIG,
    activeDatasetId: activeDatasetId ?? null,
  });
  return newDocRef;
};

type UpdatePromptParams = {
  promptId: string;
  workspaceId: string;
  name?: string;
  text?: string;
  activeDatasetId?: string | null;
  modelConfig?: ModelConfig;
};

export const updatePrompt = async ({
  promptId,
  workspaceId,
  name,
  text,
  activeDatasetId,
  modelConfig,
}: UpdatePromptParams) => {
  const promptRef = doc(
    firebase.db,
    "workspaces",
    workspaceId,
    "prompts",
    promptId
  );
  // Strip out undefined values
  const updateObj = _.pickBy(
    {
      name,
      text,
      modelConfig,
      activeDatasetId,
    },
    (v) => v !== undefined
  );
  await updateDoc(promptRef, updateObj);
};

export const deletePrompt = async ({
  workspaceId,
  promptId,
}: {
  workspaceId: string;
  promptId: string;
}) => {
  const promptRef = doc(
    firebase.db,
    "workspaces",
    workspaceId,
    "prompts",
    promptId
  );
  await deleteDoc(promptRef);
};

export const createDataset = async ({
  workspaceId,
  name,
  columns,
  data,
  length,
}: {
  workspaceId: string;
  name: string;
  columns: string[];
  data: any;
  length: number;
}): Promise<DocumentReference<DocumentData>> => {
  const datasetsRef = collection(
    firebase.db,
    "workspaces",
    workspaceId,
    "datasets"
  );
  const newDocRef = await addDoc(datasetsRef, {
    name,
    workspaceId: workspaceId,
    columns: columns,
    data: data,
    length: length,
    modifiedAt: serverTimestamp(),
    createdAt: serverTimestamp(),
  });
  return newDocRef;
};

type UpdateDatasetParams = {
  datasetId: string;
  workspaceId: string;
  name?: string;
  columns?: string[];
  data?: ModelConfig;
  length?: number;
};

export const updateDataset = async ({
  datasetId,
  workspaceId,
  name,
  columns,
  data,
  length,
}: UpdateDatasetParams) => {
  const promptRef = doc(
    firebase.db,
    "workspaces",
    workspaceId,
    "datasets",
    datasetId
  );
  // Strip out undefined values
  const updateObj = _.pickBy(
    {
      name,
      columns,
      data,
      length,
    },
    (v) => v !== undefined
  );
  await updateDoc(promptRef, updateObj);
};

export const deleteDataset = async ({
  workspaceId,
  datasetId,
}: {
  workspaceId: string;
  datasetId: string;
}) => {
  const promptRef = doc(
    firebase.db,
    "workspaces",
    workspaceId,
    "datasets",
    datasetId
  );
  await deleteDoc(promptRef);
};

export const setOpenAIApiKey = async (uid: string, apiKey: string) => {
  const userRef = doc(usersRef, uid);
  updateDoc(userRef, { openAIApiKey: apiKey });
};

export const verifyOpenAIApiKey = async (apiKey: string) => {
  try {
    const configuration = new Configuration({
      apiKey,
    });
    const openai = new OpenAIApi(configuration);
    await openai.retrieveModel("text-davinci-002");
    return true;
  } catch (err) {
    console.log(err);
  }
  return false;
};

export const getWaleApiKeys = async (uid: string) => {
  const waleApiKeysCollection = collection(firebase.db, "apiKeys");
  const querySnapshot = await getDocs(
    query(waleApiKeysCollection, 
    where("ownerUid", "==", uid))
  );

  const apiKeys: WaleApiKey[] = [];
  querySnapshot.forEach((doc) => {
    const {
      ownerUid,
      createdAt,
      name,
      workspaceId
    } = doc.data();
    apiKeys.push({
      id: doc.id,
      ownerUid,
      createdAt: createdAt?.toDate(),
      name,
      workspaceId,
    });
  });
  apiKeys.sort((a, b) => {
    // sort from old to new
    if (a.createdAt && b.createdAt) {
      return a.createdAt.getTime() - b.createdAt.getTime();
    }
    return 0;
  });
  return apiKeys;
};

export const deleteWaleApiKey = async (apiKeyId: string) => {
  const waleApiKeysCollection = collection(firebase.db, "apiKeys");
  const apiKeyRef = doc(waleApiKeysCollection, apiKeyId);
  await deleteDoc(apiKeyRef);
}

