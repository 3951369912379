import { Box } from '@chakra-ui/react'
import React from 'react'

type OverflowWrapperProps = {
  children: React.ReactNode
}

// This component is a wrapper that allows you to use overflowY="auto"
export const OverflowWrapper = ({children}: OverflowWrapperProps) => {
  return (
    <Box pos="relative" w="full" h="full">
      <Box
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        overflowY="auto"
        overscrollY={'contain'}
      >
        {children}
      </Box>
    </Box>
  )
}
