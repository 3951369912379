import { ArrowBackIcon, CheckIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Container,
  Divider,
  HStack,
  Spacer,
  Text,
  VStack,
  Link as ChakraLink,
  Input,
  InputGroup,
  useToast,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableContainer,
} from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../components/PageTitle";
import * as service from "../service";
import { useUser } from "../auth/ProtectedRoute";
import React from "react";
import { usePostHog } from "posthog-js/react";
import firebase from "../firebase";
import { collection, getDocs, where } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { set } from "lodash";
import { WaleApiKey } from "../types";

const SettingsPage = () => {
  const toast = useToast();
  const { user, refreshUser, signOut } = useUser();
  const [isSaving, setSaving] = useState(false);
  const [newApiKey, setNewApiKey] = useState<string>(user.openAIApiKey);
  const [isValidKey, setIsValidKey] = useState(true);

  const posthog = usePostHog();
  // check current api key on load
  useEffect(() => {
    const checkValidApiKey = async () => {
      const isValid = await service.verifyOpenAIApiKey(user.openAIApiKey);
      if (!isValid) {
        toast({
          title: "Invalid API key",
          status: "error",
          duration: 3000,
        });
        setSaving(false);
        return false;
      }
      return true;
    };
    checkValidApiKey().then(setIsValidKey);
  }, []);

  const onApiKeyChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNewApiKey(event.target.value);
  };

  const handleSave = async () => {
    try {
      setSaving(true);
      const isValid = await service.verifyOpenAIApiKey(newApiKey);
      if (!isValid) {
        posthog?.capture("Invalid OpenAI api key", { property: "settings" });
        toast({
          title: "Save failed",
          description: "Invalid API key",
          status: "error",
          duration: 3000,
        });
        setSaving(false);
        setIsValidKey(false);
        return;
      }

      service.setOpenAIApiKey(user.id, newApiKey).then(() => {
        posthog?.capture("Successful OpenAI api key upload", {
          property: "settings",
        });
        toast({
          title: "Saved",
          status: "success",
          duration: 3000,
        });
        setSaving(false);
        setIsValidKey(true);
        refreshUser();
      });
    } catch (error) {
      console.log(error);
      posthog?.capture("Error @saving api key", {
        property: "settings",
        errorMsg: error,
      });
      toast({
        title: "Error saving API key",
        status: "error",
        duration: 3000,
      });
    }
  };
  const navigate = useNavigate();

  const [waleApiKeys, setWaleApiKeys] = useState<WaleApiKey[]>([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [creatingApiKey, setCreatingApiKey] = useState(false);
  const createApiKey = async () => {
    setCreatingApiKey(true);
    try {
      const createKeyFunction = httpsCallable(firebase.functions, "createKey");
      const result = await createKeyFunction();
      // console.log("API Key created:", result.data);
      posthog?.capture("Created Wale API Key", { property: "settings" });
    } catch (error) {
      console.error("Error creating API Key:", error);
    }
    refreshApiKeys();
    setCreatingApiKey(false);
  };

  const [refreshWaleApiKeys, setRefreshWaleApiKeys] = useState(0);
  const refreshApiKeys = () => {
    setRefreshWaleApiKeys((prev) => prev + 1);
  };
  useEffect(() => {
    const getApiKeys = async () => {
      const apiKeys = await service.getWaleApiKeys(user.id);
      // console.log(apiKeys);
      setWaleApiKeys(apiKeys);
    };
    getApiKeys();
  }, [user.id, refreshWaleApiKeys]);

  return (
    <>
      <PageTitle title="Settings | Wale" />
      <VStack alignItems="flex-start">
        <HStack px="1%" pt="1%" w="full">
          <Button variant="ghost" pl="0" pr="0" onClick={() => navigate(-1)}>
            <ArrowBackIcon fontSize="xl" />
          </Button>
          <Text fontSize="2xl" fontWeight="bold">
            🛠️ Settings
          </Text>
          <Spacer />
        </HStack>
        <Divider />
        <HStack justifyContent="center" w="full" pt={5}>
          <Container maxW="container.md">
            <VStack>
              <VStack w="full" alignItems="flex-start">
                <Text fontSize="lg" fontWeight="semibold">
                  OpenAI API Key
                </Text>
                <Text>
                  Your API key is how Dyno interacts with OpenAI. To get an API
                  key, create one on the{" "}
                  <ChakraLink
                    href="https://beta.openai.com/account/api-keys"
                    color="brand.500"
                    isExternal
                  >
                    OpenAI API Keys page
                  </ChakraLink>
                  .
                </Text>
                <InputGroup>
                  <Input
                    isInvalid={!isValidKey}
                    placeholder="Your API Key"
                    fontFamily="mono"
                    onChange={onApiKeyChange}
                    value={newApiKey}
                  />
                </InputGroup>
                <Button
                  onClick={handleSave}
                  disabled={newApiKey === user.openAIApiKey || isSaving}
                >
                  Save
                </Button>
              </VStack>
              <VStack w="full" alignItems={"flex-start"} pt={5}>
                <Text fontSize="lg" fontWeight="semibold">
                  Wale API Keys
                </Text>
                <TableContainer
                  w="full"
                  rounded="md"
                  borderWidth="1px"
                  borderColor="gray.200"
                >
                  <Table w="full" variant="simple">
                    <Thead w="full">
                      <Tr>
                        <Th>Name</Th>
                        <Th>API Key</Th>
                        <Th>Created Date</Th>
                        <Th>Action</Th>
                      </Tr>
                    </Thead>
                    <Tbody w="full">
                      {waleApiKeys.map((key) => (
                        <Tr key={key.id}>
                          <Td>{key.name}</Td>
                          <Td>{key.id}</Td>
                          <Td>
                            {key.createdAt.toLocaleDateString("en-US", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            })}
                          </Td>
                          <Td>
                            <IconButton
                              aria-label="Delete API Key"
                              icon={<DeleteIcon />}
                              onClick={async () => {
                                await service.deleteWaleApiKey(key.id);
                                posthog?.capture("Deleted Wale API Key", {
                                  property: "settings",
                                });
                                refreshApiKeys();
                              }}
                            />
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TableContainer>
                <Button onClick={createApiKey} isLoading={creatingApiKey}>
                  Create API Key
                </Button>
              </VStack>
              {/* Logout */}
              <VStack alignItems="flex-start" w="full" pt={10} pb={10}>
                <Text fontSize="lg" fontWeight="semibold">
                  Account
                </Text>
                <Button onClick={signOut} colorScheme="gray">
                  Logout
                </Button>
              </VStack>
            </VStack>
          </Container>
        </HStack>
      </VStack>
    </>
  );
};

export default SettingsPage;
